.lead_header {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 5px 0px 0px 5px;
    border: 1px solid #bab1b1;
    border-radius: 7px;
    padding: 10px;
}

.idColor {
    color: #2170ff;
}

.idAlign {
    padding-left: 10px;
    color: #2170ff;
}

.new_line {
    margin: 0px;
}

.lead-content {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 10px;
}

.columnWidth {
    width: 400px;
}

.textStyle {
    word-wrap: break-word;
    font-weight: 450;
}

.titleFont {
    font-weight: 600;
    word-wrap: break-word;
}

.form-field-width {
    width: 200px;
}

.badgeStatus {
    white-space: nowrap;
}

.css-106c1u2-MuiBadge-badge {
    font-size: 13px !important;
    background-color: #d8defa !important;
    color: #242424 !important;
}

.lead_detail {
    margin: 0px auto;
    width: 300px;
    padding: 10px;
    border: 1px solid #bab1b1;
    border-radius: 7px;
}

.detail {
    display: flex;
    justify-content: space-around;
    align-items: center;
}

.css-140puxv-MuiTypography-root-MuiDialogTitle-root {
    margin: 10px 0px 0px 0px !important;
}

.css-1rwt2y5-MuiButtonBase-root-MuiButton-root {
    border: 1px solid rgb(170 176 181 / 50%) !important;
    color: #07090b !important;
}

.keyText {
    font-weight: 300;
    word-wrap: break-word;
}

.valueText {
    word-wrap: break-word;
    text-transform: capitalize;
}

.filterBtn {
    display: flex;
}

.css-1rwt2y5-MuiButtonBase-root-MuiButton-root {
    margin: 20px !important;
}

.css-sghohy-MuiButtonBase-root-MuiButton-root {
    margin: 20px !important;
}

.filterIcon {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.css-137ogol {
    background-color: #eaeaea;
}

.leadActionBtn {
    padding-top: 20px;
    display: flex;
    gap: 20px;
}

/* .css-uzj4r5-MuiButtonBase-root-MuiButton-root{
  padding: 5px 10px !important;
  margin-right: 10px !important;
} */

.css-1t1j96h-MuiPaper-root-MuiDialog-paper {
    max-width: 700px !important;
    width: 600px !important;
}

.checkBoxSize {
    transform: scale(1.8);
}
