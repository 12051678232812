.signup {
    background-image: url("https://coloredbrain.com/wp-content/uploads/2016/07/login-background.jpg");
    background-size: cover;
    background-repeat: no-repeat;
    max-height: 100%;
    min-height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
}

.left_section_signup {
    /* display: flex;
  flex-direction: column;
  justify-content: center; */
    background-color: #fff;
    height: 100%;
    padding: 20px;
    margin: 20px;
    border-radius: 15px;
    position: relative;
    min-width: 320px;
    max-width: 420px;
}

.verificationContainer {
    text-align: center;
}

.verificationContainer h3 {
    font-weight: 700;
    margin-top: 1.6rem;
}
.verificationContainer span {
    font-weight: 700;
    color: var(--color-btn-violet);
}

.verificationContainer p {
    font-size: 1rem;
    margin-top: 1.5rem;
    color: var(--color-darkest-gray);
    font-weight: 500;
}
