.generateScriptCopy {
    position: "absolute";
    top: 10;
    right: 10;
    cursor: "copy";
    color: "grey";
}

.shadow-hover {
    transition: box-shadow 0.3s;
    box-shadow: 3px 3px 10px rgba(0, 0, 0, 0.1);
    border-radius: 15px;
    width: 21.3rem;
    min-height: 7rem;
    margin: 0.8rem;
}

.shadow-hover-add-form {
    transition: box-shadow 0.3s;
    box-shadow: 3px 3px 10px rgba(0, 0, 0, 0.1);
    border-radius: 15px;
    width: auto;
    min-height: 9rem;
    margin: 0.8rem;
    cursor: pointer;
}

.shadow-hover:hover {
    box-shadow: 0 10px 15px rgba(0, 0, 0, 0.1);
}

.shadow-hover-add-form:hover {
    box-shadow: 0 10px 15px rgba(0, 0, 0, 0.1);
}

.iconBtn {
    padding: 0 !important;
    margin-left: 30px !important;
    margin-top: -12px !important;
    border-radius: 30px !important;
    height: 60px !important;
    width: 20px !important;
}

.logoSrc {
    max-height: 4.2rem;
}

.titleName {
    font-size: 0.85rem;
    color: rgb(136, 136, 136);
}

.formCategoryName {
    font-size: 1rem;
    color: rgb(72, 72, 72);
}

.noWebhook {
    padding: 2rem;
    border-radius: 30px;
    color: rgb(162, 162, 162);
    margin-top: 3rem;
    text-align: center;
}

.categoryCard {
    border-radius: 10px !important;
    box-shadow: 3px 4px 10px 1px rgba(0, 0, 0, 0.1) !important;
}

.css-ig8x1w {
    border: none !important;
    border-radius: 10px;
    box-shadow: 4px 8px 18px 1px rgba(0, 0, 0, 0.1) !important;
}

.radioBtn {
    width: 1.3rem;
    height: 2rem;
}

.category-logo {
    width: 50%;
}

.sampleLogo {
    color: rgb(221, 107, 7);
}

.popupMenu {
    box-shadow: 0 10px 15px rgba(0, 0, 0, 0.1) !important;
}

.webHookCircle {
    width: 16px;
    height: 15px;
    border-radius: 50%; /* Makes it a circle */
    margin-right: 10px;
    margin-top: 0.2rem;
}

.connected {
    background-color: green;
}

.disconnected {
    background-color: rgb(145, 145, 145);
}

.connectedContainer {
    margin-top: 1rem;
}

.webhooksInner {
    grid-template-columns: repeat(4, 1fr);
    display: grid;
}

.rangePickerErrText {
    font-size: 14px;
    color: #fb0000;
    font-family: serif;
}
