.errorBoundary {
    padding: 48px 24px;
    width: 100%;
    display: flex;
    justify-content: center;
}

.errorBoundaryAlert {
    width: 100%;
    max-width: 720px;
}

.errorBoundaryRefreshPage {
    text-decoration: underline;
    cursor: pointer;
}

.dialogDescription {
    margin-top: 20px;
}
