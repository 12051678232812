.billTitle {
    display: flex;
    justify-content: flex-start;
    align-items: center;
}

.iconMargin {
    margin-right: 10px;
}

._planText {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

._planText h5 {
    font-size: 1.1rem;
}

.planDetails {
    margin-right: 15px;
    color: rgb(106, 106, 106);
    font-size: 14px;
}

.css-axua52-MuiButtonBase-root-MuiButton-root {
    background: rgb(255, 255, 255) !important;
    color: rgb(246, 115, 8) !important;
    border-radius: 10px !important;
    box-shadow: 2px 2px 5px rgba(107, 107, 107, 0.1) !important;
    text-transform: none !important;
}
