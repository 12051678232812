.membership-section .css-12wnr2w-MuiButtonBase-root-MuiCheckbox-root {
    padding: 40px !important;
}

.textTypeWidth {
    width: 100px;
}
.numOfUsers {
    visibility: visible !important;
    width: 100px;
    margin: 0 auto;
    height: 35px;
    text-align: center;
}

.txt-center {
    text-align: center;
}
input.largerCheckbox {
    width: 30px;
    height: 30px;
    accent-color: #c98204;
}
