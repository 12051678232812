:root {
    --nav-color: #313c56;
    --black-base: #212121;
    --color-blue: #233044;
    --color-blue-light: #353b50;
    --color-gray: #efefef;
    --color-dark-gray: #c4d1e1;
    --color-darker-gray: #dddddd;
    --color-darkest-gray: #919191;
    --color-bg: #f1f2f7;
    --color-white: #ffffff;
    --color-black: #000000;
    --color-black-overlay: #00000026;
    --color-online: #0cdb0c;
    --color-success: #0c910c;
    --color-offline: #ffa330;
    --color-orange: #ffb8a9;
    --color-icon-orange: #fd8212;
    --color-chat-right: #e8e8e8;
    --color-chat-left: #d8e7ea;
    --color-btn-orange: #fd8212;
    --color-btn-violet: #6a4fe3;
    --color-pink-button: #d7228b;
    --color-green-button: #63c974;

    --whitebase: #ffffff;

    --black-100: #e6e6e6;
    --black-200: #939393;
    --black-300: #666666;
    --black-base: #212121;

    --primary-900: #071e36;
    --primary-800: #0c355e;
    --primary-700: #0f4880;
    --primary-600: #125699;
    --primary-500: #1873cc;
    --primary-400-base: #1e90ff;
    --primary-300: #62b1ff;
    --primary-200: #a5d3ff;
    --primary-100: #e9f4ff;

    --secondary-700: #330a1f;
    --secondary-600: #66153e;
    --secondary-400-base: #ff349b;
    --secondary-500: #b3246d;
    --secondary-300: #ff71b9;
    --secondary-200: #ffaed7;
    --secondary-100: #ffebf5;

    --tertiary-400-base: #5540fb;
    --tertiary-300: #8879fc;
    --tertiary-200: #bbb3fd;
    --tertiary-100: #ccc6fe;
    --tertiary-500: #3b2db0;
    --tertiary-600: #221a64;
    --tertiary-700: #110d32;

    --success-100: #d1eedd;
    --success-200: #a3ddbc;
    --success-300: #5dc389;
    --success-400-base: #18a957;
    --success-500: #11763d;
    --success-600: #0a4423;
    --success-700: #052211;

    --warning-100: #fff1d7;
    --warning-200: #ffe4af;
    --warning-300: #ffcf74;
    --warning-400-base: #ff8a38;
    --warning-500: #b38327;
    --warning-600: #664b16;
    --warning-700: #33250b;

    --error-100: #f9d0d9;
    --error-200: #f2a2b3;
    --error-300: #e95c7b;
    --error-400-base: #df1642;
    --error-500: #9c0f2e;
    --error-600: #59091a;
    --error-700: #2d040d;

    --whitebase-10: rgba(255, 255, 255, 0.1);
    --whitebase-20: rgba(255, 255, 255, 0.2);
    --black-30: rgba(0, 0, 0, 0.3);
    --whitebase-30: rgba(255, 255, 255, 0.3);

    --z-index-calendar-10: 10;
}
