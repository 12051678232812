.tabs-menu button {
    /* border: 1px solid #f3f3f3; */
    border-radius: 0;
    background-color: transparent;
    font-weight: 600;
}

.tabs-menu button.Mui-selected {
    color: var(--color-btn-violet);
    border: 0;
    background-color: #fff;
    border-radius: 10px;
    margin: 5px;
}

.tabs-menu .MuiTabs-flexContainer {
    gap: 10px;
    background-color: #f2edfd;
    border-radius: 10px;
    overflow-x: scroll;
    -ms-overflow-style: none;
}
.tabs-menu .MuiTabs-flexContainer::-webkit-scrollbar {
    display: none;
}

.tabs-menu .MuiTabs-indicator {
    height: 0;
}

.bg-class {
    background: var(--color-white);
    padding: 0;
    height: auto;
    border-radius: 20px;
    margin: 25px 0px;
}

.bg-class .MuiBox-root {
    padding: 10px 0;
}

.iconAlign {
    text-align: left;
    display: flex;
    justify-content: space-between;
    align-items: center;
}
