.popup-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.7);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
}

.popup-content {
    position: relative;
    background: #fff;
    padding: 20px;
    border-radius: 8px;
    max-width: 90%;
    max-height: 90%;
    text-align: center;
}

.popup-content img,
.popup-content video {
    max-width: 100%;
    max-height: 100%;
    border-radius: 8px;
}

.close-btn {
    position: absolute;
    top: -11px;
    right: -14px;
    background: transparent;
    border-radius: 61%;
    font-size: 30px;
    font-weight: bold;
    color: #333;
    cursor: pointer;
    background-color: red;
    color: white;
    height: 30px;
    width: auto;
}

.close-btn:hover {
    color: red;
    background-color: white;
    border: 1px solid red;
}
