.errorWrapper {
    /* position: absolute; */
    /* display: flex; */
    /* justify-content: center;
    align-items: center; */
    /* left: 10px; */
    /*margin-top: -4px;*/
}

.errorWrapper > p {
    font-size: 12px;
    font-weight: 500;
    color: var(--error-400-base);
    margin: 0;
}

.warningIcon {
    margin-right: 5px;
}
