.logo-area {
    display: flex !important;
    align-content: center;
    justify-content: center !important;
    /* background: #fff; */
}

.nameText {
    font-size: 17px;
    font-family: "Poppins";
}

.roleText {
    font-size: 0.9rem;
    font-weight: 400;
    font-family: "Poppins";
}

.userIcon {
    transform: scale(2.2);
    fill: var(--color-btn-violet) !important;
}

.nameSection {
    display: flex;
    align-items: center;
    gap: 20px;
    margin-left: 10px;
}

.logo-area img {
    width: 80%;
    aspect-ratio: 4/2;
    object-fit: contain;
    padding: 10px;
    margin-bottom: 20px;
}

.topbarAvatar {
    position: relative !important;
}

.css-1eaqvnp-MuiPaper-root-MuiPopover-paper-MuiMenu-paper {
    position: absolute !important;
    top: 50px !important;
    /* left: 1375px !important; */
}

.quotaWarning {
    color: #6e2a2a;
    background-color: #ffdada;
    padding: 1rem;
    font-size: 1.1rem;
}
.css-1nuawl5-MuiDrawer-docked .MuiDrawer-paper {
    display: flex;
    justify-content: space-between;
}

.backgroundProgressbar {
    color: var(--color-gray) !important;
}

.frontProgressbar {
    color: var(--color-btn-violet) !important;
    animation-duration: 550ms;
    position: absolute;
}
