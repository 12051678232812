.d-block {
    display: block;
}

.d-none {
    display: none;
}

.flex {
    display: flex;
}

.flex-row {
    flex-direction: row;
}

.flex-shrink-0 {
    flex-shrink: 0;
}

.flex-col {
    flex-direction: column;
}

.justify-between {
    justify-content: space-between;
}

.justify-around {
    justify-content: space-around;
}

.justify-center {
    justify-content: center;
}

.justify-start {
    justify-content: flex-start;
}

.justify-end {
    justify-content: flex-end;
}

.items-center {
    align-items: center;
}

.flex-1 {
    flex: 1 1 0%;
}

.relative {
    position: relative;
}

.absolute {
    position: absolute;
}

.resize-vertical {
    resize: vertical;
}

.text-center {
    text-align: center;
}

/* COLOR */
.text-danger {
    color: var(--error-400-base);
}

/* SPACE */

.space-x-16 > * + * {
    margin-right: 0;
    margin-left: 16px;
}

.space-x-20 > * + * {
    margin-right: 0;
    margin-left: 20px;
}

.space-y-4 > * + * {
    margin-top: 4px;
    margin-bottom: 0;
}

.space-y-8 > * + * {
    margin-top: 8px;
    margin-bottom: 0;
}

.space-y-12 > * + * {
    margin-top: 12px;
    margin-bottom: 0;
}

.space-y-16 > * + * {
    margin-top: 16px;
    margin-bottom: 0;
}

/* SIZE */

.w-full {
    width: 100%;
}

.w-auto {
    width: auto;
}

.w-20 {
    width: 20px;
}

.w-24 {
    width: 24px;
}

.h-full {
    height: 100%;
}

.h-auto {
    height: auto;
}

.h-24 {
    height: 24px;
}

.h-64 {
    height: 64px;
}

.h-96 {
    height: 96px;
}

/* TYPOGRAPHY */

.text-14 {
    font-size: 14px;
}

.text-16 {
    font-size: 16px;
}

.font-semibold {
    font-weight: 600;
}

.leading-normal {
    line-height: 1.5;
}

.text-left {
    text-align: left;
}

.text-center {
    text-align: center;
}

.text-right {
    text-align: right;
}

/* BORDER */

.border-2 {
    border-width: 2px;
}

.border-t-1 {
    border-top-width: 1px;
}

.border-t-2 {
    border-top-width: 2px;
}

.border-b-2 {
    border-bottom-width: 2px;
}

.border-line {
    border-color: #e5e5e5;
}

.border-primary {
    border-color: var(--primary-400-base);
}

/* margin */

.my-0 {
    margin-top: 0;
    margin-bottom: 0;
}

.my-8 {
    margin-top: 8px;
    margin-bottom: 8px;
}

.my-16 {
    margin-top: 16px;
    margin-bottom: 16px;
}

.my-24 {
    margin-top: 24px;
    margin-bottom: 24px;
}

.my-40 {
    margin-top: 40px;
    margin-bottom: 40px;
}

.ml-4 {
    margin-left: 4px;
}

.ml-6 {
    margin-left: 6px;
}

.ml-40 {
    margin-left: 40px;
}

.mt-0 {
    margin-top: 0;
}

.mt-4 {
    margin-top: 4px;
}

.mt-8 {
    margin-top: 8px;
}

.mt-10 {
    margin-top: 10px;
}

.mt-16 {
    margin-top: 16px;
}

.mt-20 {
    margin-top: 20px;
}

.mt-24 {
    margin-top: 24px;
}

.mt-32 {
    margin-top: 32px;
}

.mb-0 {
    margin-bottom: 0;
}

.mb-2 {
    margin-bottom: 2px;
}

.mb-4 {
    margin-bottom: 4px;
}

.mb-8 {
    margin-bottom: 8px;
}

.mb-16 {
    margin-bottom: 16px;
}

.mb-24 {
    margin-bottom: 24px;
}

.mb-32 {
    margin-bottom: 32px;
}

.mb-48 {
    margin-bottom: 48px;
}

.px-6 {
    padding-left: 6px;
    padding-right: 6px;
}

.px-8 {
    padding-left: 8px;
    padding-right: 8px;
}

.px-24 {
    padding-left: 24px;
    padding-right: 24px;
}

.px-40 {
    padding-left: 40px;
    padding-right: 40px;
}

.py-4 {
    padding-top: 4px;
    padding-bottom: 4px;
}

.py-8 {
    padding-top: 8px;
    padding-bottom: 8px;
}

.py-24 {
    padding-top: 24px;
    padding-bottom: 24px;
}

.pt-4 {
    padding-top: 4px;
}

.pb-24 {
    padding-bottom: 24px;
}

.pb-30 {
    padding-bottom: 30px;
}

.pl-6 {
    padding-left: 6px;
}

.pr-12 {
    padding-right: 12px;
}

.p-30 {
    padding: 30px;
}
/* CURSOR */

.cursor-pointer {
    cursor: pointer;
}

.cursor-copy {
    cursor: copy;
}
