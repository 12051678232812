.roleInputForm {
    padding: 0.1rem;
    font-size: 14px;
    color: #666666;
    border: none;
    border-bottom: 1px solid #efefef;
    margin-top: 0.2rem;
    width: 100%;
    outline: none;
}

label {
    font-weight: 500;
    display: block;
}

.staffTable {
    min-height: 30vh;
    height: 78vh;
    width: 100%;
    background-color: white;
}
