.root {
    display: block;
    width: 100%;
    position: relative;
}
.iconWrapper {
    position: absolute;
    width: 48px;
    height: 40px;
    border-top-left-radius: 6px;
    border-bottom-left-radius: 6px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: var(--primary-400-base);
}

.icon {
    width: 19px;
    height: 19px;
    fill: var(--whitebase);
}

.input {
    width: 100%;
    height: 50px;
    border: none;
    /* background-color: transparent; */
    outline: none;
    padding: 8px 14px;
    padding-left: 15px;
    font-family: Poppins, sans-serif;
    font-size: 16px;
    line-height: 1.5;
    letter-spacing: normal;
    border-radius: 6px;
    margin: 0;
    box-shadow:
        0 2px 4px 0 rgba(48, 49, 51, 0.1),
        0 0 1px 0 rgba(48, 49, 51, 0.05);
    color: var(--black-base);
}

.input:disabled {
    background-color: var(--black-100);
}

.input:focus {
    box-shadow: 0 0 0 2px var(--primary-400-base);
}

.input::placeholder {
    font-family: Poppins, sans-serif;
    font-size: 16px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: normal;
    color: var(--black-300);
    padding-left: 0;
}
