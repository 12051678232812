label {
    font-weight: 500;
    display: block;
}

.staffTable {
    min-height: 30vh;
    height: 78vh;
    width: 100%;
    background-color: white;
}

.send-button {
    width: 170px;
}
