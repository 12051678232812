.dashboard_inner .box {
    background-color: #fff;
    border-radius: 10px;
}

.dashboard_inner .top,
.dashboard_inner .bottom {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 15px;
}

.dashboard_inner .middle {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    /* margin: 15px 0; */
}

.dashboard_inner .middle .box {
    text-align: center;
}

.dashboard_inner .middle .box img {
    width: 50%;
}

.dashboard_inner .bottom {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 15px;
}

.dashboard_inner table tr td,
.dashboard_inner .box table tr th {
    padding: 15px 10px;
}

.dashboard_inner .MuiDataGrid-columnHeaders {
    background-color: #fd8212;
    color: #fff;
}

.dashboard_inner .MuiDataGrid-columnHeader {
    text-align: center;
    font-size: 15px;
}

.dashboard_inner table tbody tr:nth-child(even) {
    background-color: #f5f5f5;
}

/* .alignment{
  display: flex;
  justify-content: end;
  margin-top: 30px;
}

.textCenter{
  display: flex;
  align-items: center;  
}

.pieGraphAlign{
  display: flex;
  justify-content: center;
}

.list_title{
  margin: -15px 0px 20px 0px;
}

.widgetDisplay{
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: -10px;
} */

.widgetDisplay {
    padding: 20px;
    border-radius: 15px;
    background: #fff;
    color: #545454;
}

.list_title {
    margin-bottom: 20px;
    font-size: 18px;
}
