.active-drag {
    user-select: none;
    padding: 16px;
    margin: 0px;
    color: rgb(255, 255, 255);
    font-weight: 600;
    text-transform: capitalize;
}
.drag-main {
    display: flex;
    flex-flow: column wrap;
    text-align: center;
    gap: 10px;
    margin: 5px 20px;
}
.active-item {
    background: var(--color-icon-orange);
}
.inactive-item {
    background: var(--color-darker-gray);
    color: var(--black-base);
}
